import { useAnimation } from "framer-motion";
import React from "react";
import { useInView } from "react-intersection-observer";

interface DeliveryCardProps {
	logo: JSX.Element;
	title: string;
	description: string;
}

function DeliverCard(props: DeliveryCardProps) {
	const { logo, title, description } = props;
	const { ref, inView } = useInView({ threshold: 0.5 });
	const animationControls = useAnimation();

	React.useEffect(() => {
		if (inView) {
			animationControls.start({ opacity: 1, y: 0 });
		} else {
			animationControls.start({ opacity: 0, y: 50 });
		}
	}, [inView]);

	return (
		<div ref={ref} className="delivery-card-container">
			<div className="delivery-logo">{logo}</div>
			<div className="delivery-title">{title}</div>
			<div className="delivery-description">{description}</div>
		</div>
	);
}

export default DeliverCard;
