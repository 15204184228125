import { AboutSection, WelcomeSection } from "components";
import ContactSection from "components/ContactSection";
import DeliveriesSection from "components/DeliveriesSection";
import "./styles.scss";

function MainPage() {
	return (
		<div className="main-page">
			<WelcomeSection />
			<AboutSection />
			<DeliveriesSection />
			<ContactSection />
		</div>
	);
}

export default MainPage;
