import { IcoLaptop, IcoNotebook, IcoSmug } from "assets/icons";
import { motion, useAnimation } from "framer-motion";
import { useWindowSize } from "hooks";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import DeliveryCard from "./DeliveryCard";
import "./styles.scss";

function Carousel() {
	const { ref, inView } = useInView({ threshold: 0.5 });
	const { width } = useWindowSize();
	const animationControls = useAnimation();
	const { t } = useTranslation();

	const firstCarouselItem = useMemo(() => (width > 1024 ? 1 : 0), [width]);

	React.useEffect(() => {
		if (inView) {
			animationControls.start({ opacity: 1, y: 0 });
		} else {
			animationControls.start({ opacity: 0, y: 50 });
		}
	}, [inView]);

	const deliveries = [
		{
			logo: <IcoSmug />,
			title: t("management_and_monitoring_title"),
			description: t("management_and_monitoring_description")
		},
		{
			logo: <IcoNotebook />,
			title: t("research_and_analysis_title"),
			description: t("research_and_analysis_description")
		},
		{
			logo: <IcoLaptop />,
			title: t("strategy_title"),
			description: t("strategy_description")
		}
	];

	return (
		<div ref={ref} className="carousel-container">
			<motion.div animate={animationControls} initial={{ opacity: 0, y: 50 }}>
				<Swiper
					modules={[Navigation, Pagination]}
					style={
						{
							"--swiper-navigation-size": "25px"
						} as any
					}
					navigation
					pagination={{ clickable: true }}
					centeredSlides
					breakpoints={{
						640: { slidesPerView: 0.5 },
						768: { slidesPerView: 1.5 },
						1024: { slidesPerView: 3.5 }
					}}
					initialSlide={firstCarouselItem}
				>
					{deliveries.map((delivery, index) => (
						<SwiperSlide key={`projects-gallery-${index}`}>
							<DeliveryCard
								logo={delivery.logo}
								title={delivery.title}
								description={delivery.description}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</motion.div>
		</div>
	);
}

export default Carousel;
