import { IcoProps } from "./types";

export default function IcoEmail(props: IcoProps) {
	const { size = "37", color = "black" } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 37 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 13.5476V31.6109H36.1267V13.5476L18.0634 24.8372L0 13.5476ZM0 4.51587V9.03171L18.0634 20.3213L36.1267 9.03171V4.51587H0Z"
				fill={color}
			/>
		</svg>
	);
}
