import { IcoBrand, IcoEmail, IcoLinkedin } from "assets/icons";
import { useWindowSize } from "hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

function ContactSection() {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const contactIconSize = useMemo(() => (width > 700 ? "37" : "50"), [width]);
	const brandIconSize = useMemo(() => (width > 700 ? "150" : "100"), [width]);

	return (
		<section className="contact-section" data-bgcolor="yellow" id="contato">
			<div className="contact-wrapper">
				<div className="call-to-action">
					<h1>
						<div className="first-line">{t("chat_part1")}</div>
						<div className="first-line bold">{t("chat_part2")}</div>
					</h1>
				</div>
				<div className="contact-options-section">
					<IcoBrand theme="purple" size={brandIconSize} fullIcon={false} />
					<div className="contact-options">
						<a
							href="mailto:jacque.lmenassa@gmail.com"
							className="contact-option"
						>
							<IcoEmail size={contactIconSize} />
							<div className="text">jacque.lmenassa@gmail.com</div>
						</a>
						<a
							className="contact-option"
							href="https://www.linkedin.com/in/jacquelinemenassa/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<IcoLinkedin size={contactIconSize} />
							<div className="text">/in/jacquelinemenassa</div>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ContactSection;
