import { ImgJacque } from "assets/images";
import ImgJacqueMobile from "assets/images/img-jacque-mobile";
import { useWindowSize } from "hooks";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./styles.scss";

function AboutSection() {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const jacqueImage = useMemo(
		() => (width > 1200 ? <ImgJacque /> : <ImgJacqueMobile />),
		[width]
	);

	return (
		<section className="about-section" data-bgcolor="white" id="sobre">
			<div className="about-wrapper">
				<div className="presentation">
					<h1>
						<div className="first-line">{t("hey")}</div>
					</h1>
					<div>
						<p>
							<Trans i18nKey="first_presentation_paragraph" />
						</p>
						<p>
							<Trans i18nKey="second_presentation_paragraph" />
						</p>
						<p>
							<Trans i18nKey="third_presentation_paragraph" />
						</p>
					</div>
				</div>
				<div className="image">{jacqueImage}</div>
			</div>
		</section>
	);
}

export default AboutSection;
