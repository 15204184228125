import { BrowserRouter as Router } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "App.scss";
import Routers from "router";

export function App() {
	return (
		<Router>
			<Routers />
		</Router>
	);
}
