import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: {
					about: "about",
					deliveries: "deliveries",
					works: "works",
					contact: "contact",
					strategic_planning: "strategic planning",
					hey: "hey,",
					first_presentation_paragraph:
						"My name is Jacqueline Menassa, but you can call me Jacque.",
					second_presentation_paragraph:
						"I love new experiences and diving into different cultures, cuisines, and stories. I started my career in advertising as an account manager and also wrote articles for a news portal.",
					third_presentation_paragraph:
						"Throughout my journey, I have worked with clients like <strong>Natura, Vivo, Bauducco, Doriana, and Protea</strong>, always focusing on creating strategies that authentically connect brands and people.",
					chat_part1: "let's",
					chat_part2: "talk?",
					research_and_analysis_title: "research and analysis",
					research_and_analysis_description:
						"about consumer, category, market, and culture - utilizing methods such as qualitative and quantitative research, desk research, trend studies, and benchmarking.",
					strategy_title: "strategy",
					strategy_description:
						"developing communication plans, creating creative briefs, defining strategic territories, and mapping consumer journeys.",
					management_and_monitoring_title: "management and monitoring",
					management_and_monitoring_description:
						"monitoring and supporting the different areas involved in projects, analyzing results, and developing action plans."
				}
			},
			pt: {
				translation: {
					about: "sobre",
					deliveries: "entregas",
					works: "trabalhos",
					contact: "contato",
					strategic_planning: "planejamento estratégico",
					hey: "oie,",
					first_presentation_paragraph:
						"me chamo Jacqueline Menassa, mas pode me chamar de Jacque.",
					second_presentation_paragraph:
						"Adoro novas experiências e mergulhar em diferentes culturas, culinárias e histórias. Comecei minha carreira na publicidade como atendimento e também escrevi matérias para um portal de notícias.",
					third_presentation_paragraph:
						"Em minha trajetória, atendi clientes como <strong>Natura, Vivo, Bauducco, Doriana e Protea</strong>, sempre focando em criar estratégias que conectam marcas e pessoas de maneira autêntica.",
					chat_part1: "bora bater",
					chat_part2: "um papo?",
					research_and_analysis_title: "pesquisa e análise",
					research_and_analysis_description:
						"sobre consumidor, categoria, mercado e cultura - utilizando métodos como pesquisas qualitativas, quantitativas, desk research, estudo de tendências e benchmarks.",
					strategy_title: "estratégia",
					strategy_description:
						"desenvolvendo planos de comunicação,  elaboração de briefings criativos, definição de territórios estratégicos, mapeamento de jornadas do consumidor.",
					management_and_monitoring_title: "gestão e acompanhamento",
					management_and_monitoring_description:
						"acompanhamento e suporte às diferentes áreas envolvidas nos projetos, análises de resultados e planos de ação."
				}
			}
		},
		fallbackLng: "pt",
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
