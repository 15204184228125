import { IcoProps } from "./types";

export default function IcoLinkedin(props: IcoProps) {
	const { size = "37", color = "black" } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 37 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M33.9386 0H2.70574C1.21211 0 0 1.1836 0 2.64109V34.0089C0 35.4684 1.21211 36.6535 2.70574 36.6535H33.9386C35.4333 36.6535 36.6535 35.4684 36.6535 34.0089V2.64109C36.6535 1.1836 35.4333 0 33.9386 0ZM10.8708 31.2339H5.43185V13.7425H10.8708V31.2339ZM8.15134 11.3509C6.40877 11.3509 5.00015 9.93871 5.00015 8.19766C5.00015 6.45917 6.40877 5.04699 8.15134 5.04699C9.88983 5.04699 11.302 6.45917 11.302 8.19766C11.302 9.93871 9.88983 11.3509 8.15134 11.3509ZM31.2319 31.2339H25.8015V22.7282C25.8015 20.6991 25.7618 18.09 22.9757 18.09C20.1462 18.09 19.715 20.2999 19.715 22.5811V31.2344H14.2806V13.7425H19.4961V16.1311H19.5704C20.2964 14.7566 22.07 13.3062 24.7152 13.3062C30.2162 13.3062 31.2324 16.9278 31.2324 21.6393L31.2319 31.2339Z"
				fill={color}
			/>
		</svg>
	);
}
